import { Component, AfterViewInit, OnInit } from '@angular/core';
import { EntriesService } from '../services/entries.service';
import { PersonsService } from '../services/persons.service';
import { errorNotification, successNotification } from '../services/notifications.service';
import { AuthService } from '../services/auth.service';
import { CardService } from '../services/card.service';
import { EmailsService } from './../services/emails.service';
import { Firestore, WriteBatch, writeBatch } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { environment } from './../../environments/environment';
declare var $: any;

@Component({
  selector: 'app-checkout-reining',
  templateUrl: './checkout-reining.component.html',
  styleUrls: ['./checkout-reining.component.scss']
})
export class CheckoutReiningComponent implements AfterViewInit, OnInit {
  public drafts: any;
  public paying: any[];
  public showInfo: any;
  public actions: any;
  public cartTotals: any;
  public totalToPay: number;
  public email: string;
  public uid: string;
  public showID: string;
  public club_id: string;
  private showName: string;
  public stripeCustomer: StripeCustomer;
  public metadata: any;
  public description: string;
  public defaultCardHolder: string;

  constructor(
    private _entriesService: EntriesService,
    private _personsService: PersonsService,
    private _authService: AuthService,
    private _cardService: CardService,
    private _emailsService: EmailsService,
    private _router: Router,
    private _firestore: Firestore,
  ) {
    this.cartTotals = {
      total: 0,
      entries: 0
    }
    this.showInfo = {};
    this.drafts = [];
    this.paying = [];
    this.actions = {
      canGoToPayment: false,
      cardFormIsOK: false,
      StripeFormVisible: false,
      moveObject: true
    }
    this.totalToPay = 0;
    this.email = localStorage.getItem('email')||(sessionStorage.getItem('email')||'');
    this.uid = localStorage.getItem('user_document_id')||(sessionStorage.getItem('user_document_id')||'');
    this.showID = sessionStorage.getItem('showID')||'';
    this.showName = sessionStorage.getItem('showname')||'';
    this.club_id = sessionStorage.getItem('club_id')||'';
    this.stripeCustomer = { id: '', name: '' };
    this.metadata = null;
    this.description = '';
    this.defaultCardHolder = '';
  }

  async ngOnInit(){
    const user = await this._authService.getLoggedUser();
    if(!this.email && user.email){
      this.email = user.email;
      localStorage.setItem('email', this.email);
    }
    if(!this.uid){
      this._personsService.getDocumentByEmail(this.email).then((doc: any) => {
        this.uid = doc.uid;
        localStorage.setItem('user_document_id', this.uid);
      });
    }
    this.getStripeCustomer();
  }

  ngAfterViewInit(): void {
    this.getConcurso();
  }

  public async getConcurso() {
    const response: any = await this._entriesService.getEntryInfo(this.showID);
    // Set the showInfo properties with the response data
    this.showInfo.entryAmount = response.entryAmount;
    this.showInfo.entriesDue = response.entriesDue;
    this.showInfo.cardPayment = response.cardPayment;
    this.showInfo.checkPayment = response.checkPayment;
    this.showInfo.square_config = response.square_config || null;
    this.getDrafts();
    return response;
  }

  public async getDrafts() {
    const responseDrafts = await this._entriesService.getEntries(this.uid, this.showID, 'draft');
    if (responseDrafts.error) {
      errorNotification('Error', `Error getting drafts. ${responseDrafts.message}`);
      return;
    }
    this.drafts = responseDrafts;

    // Covertir los cargos de string a objeto
    this.drafts.forEach((draft: any) => {
      draft.pruebas.forEach((prueba: any) => {
        prueba.charges = JSON.parse(prueba.charges);
      });
    });
  }

  async getStripeCustomer() {
    let consultar = false;
    //1. Getting Payment Info...
    // Check if the user has a stripe customer id
    let { customer, reason } = await this._cardService.getStripeCustomer(this.uid, sessionStorage.getItem('club_id')||'').then(customer => ({ customer, reason: null })).catch(reason => ({ reason, customer: null }));
    //Validar si el cliente existe, tiene customer_id y el id no contiene el prefijo 'cus_' (No es un id de stripe valido)
     if(customer && (customer.customer_id||'') && !(`${customer.customer_id||''}`.includes('cus_'))){
      await this._cardService.removeStripeCustomerId(this.uid, sessionStorage.getItem('club_id')||'');
      reason = {
        error: {
          code: 'invalid_customer_id',
          message: `Invalid Stripe Customer ID: ${customer.customer_id||''}`
        }
      };
      consultar = true;
      this.sendErrorEmail(reason.error.code, reason.error.message, this.email);
      console.log(reason);
      //return errorNotification('Error', `Invalid Stripe Customer ID: ${customer.customer_id||''}`);
    } else if(customer && !(customer.customer_id||'')){//Si existe el objeto cliente pero no tiene customer_id
      await this._cardService.removeStripeCustomerId(this.uid, sessionStorage.getItem('club_id')||'');
      reason = {
        error: {
          code: `not_found`,
          message: `Empty Stripe Customer ID, user: ${this.uid} and show_id ${this.showID} ${customer.customer_id||''}`
        }
      };
      consultar = true;
      this.sendErrorEmail(reason.error.code, reason.error.message, this.email);
      console.log(reason);
      //return errorNotification('Error', `Empty Stripe Customer ID, user: ${this.uid} and show_id ${showID} ${customer.customer_id||''}`);
    }

    if(consultar){
      //const customers = await this._cardService.searchStripeCustomers([this.email], +(this.showID), !environment.production);
      //customer get the first customer if exists from array customers
      const [firstCustomer] = await this._cardService.searchStripeCustomers([this.email], +(this.showID), !environment.production);
      if(firstCustomer){
        reason = null;
        customer = { customer_id: firstCustomer.id, name: firstCustomer.name||'' };
      }
    }

    //Validar si existe un error
    if(reason && reason.error && ['not_found', 'invalid_customer_id'].includes(reason.error.code)){
      // If the user doesn't have a stripe customer id, create one
      //2. Creating Customer...
      const { customer, reason } = await this._cardService.createStripeCustomer(this.uid, this.showID, sessionStorage.getItem('club_id')||'', this.email).then((r: any) => r).catch(reason => ({ reason }));
      if(reason) return errorNotification('Error', reason.error.message||(reason.error||reason));
      this._emailsService.sendEmail('User Created Stripe nvm ' + this.email + ' with ID: ' + customer.id, `User: ${this.email}  Selected Show: ${this.showName} with ID: ${this.showID} and Club ID: ${sessionStorage.getItem('club_id')||''}`);
      //3. Payment Info OK, setting up info...
      this.stripeCustomer.id = customer.id;
      this.stripeCustomer.name = customer.name||'';
    } else if(reason){
      this.sendErrorEmail('4E. Error getting Stripe Customer ID', 'unknown error', this.email);
      return errorNotification('Error', reason.error.message||(reason.error||reason));
    } else{
      //4. Payment Info OK, setting up info...
      this.stripeCustomer.id = customer.customer_id;
      this.stripeCustomer.name = customer.name||'';
    }
  }

  public async deleteDraft(uid: string) {
    const { reason } = await this._entriesService.deleteDocument(this.uid, uid).then(() => ({ reason: null })).catch(reason => ({ reason }));
    if (reason) {
      errorNotification(reason.error.code, `Error deleting draft. ${reason.error.message}`);
      return;
    }
    this.getDrafts();
  }

  public moveObject(object: any, sourceArray: any[], destinationArray: any[]): void {
    const index = sourceArray.indexOf(object);
    if (index !== -1) {
      sourceArray.splice(index, 1); // Remove the object from the source array
      destinationArray.push(object); // Add the object to the destination array
    }
    //Usar total calculado en lugar de deposit fee
    this.cartTotals.total = this.paying.reduce((acc, e) => acc + parseFloat(e.balance||(this.showInfo.entryAmount||'0')), 0);
    this.totalToPay = this.cartTotals.total;
    this.actions.canGoToPayment = (this.paying.length > 0);
    this.setMetadata();
  }

  public selectPaymentMethod() {
    this.actions.StripeFormVisible = true;
    this.actions.moveObject = false;
  }

  public returnToSelectEntries() {
    this.actions.StripeFormVisible = false;
    this.actions.moveObject = true;
  }

  public async handleSubmit(paymentIntent: any) {
    // Create a new batch
    const batch: WriteBatch = writeBatch(this._firestore);
    // Create an array of promises
    const promises: Promise<any>[] = this.paying.map(e => {
      // Update the entry status to 'Pending Show Approval' in FS
      batch.set(e.ref, { status: 'Pending Show Approval' }, { merge: true });
      //Map data to send to the API endpoint
      const additionalRiders = e.riders.map((r: any) => ({
        riderNrha: r.nrha || (r.riderNrha||''),
        riderName: r.firstname || (r.riderFirstname||''),
        riderMiddleName: '',
        riderLastName: r.lastname || (r.riderLastname||''),
        riderFei: r.FEI || (r.riderFEI||''),
        //Address
        riderAddress: r.riderAddress||'',
        riderAddress2: r.riderAddress2||'',
        riderCountry: r.riderCountry||'',
        riderCity: r.riderCity||'',
        riderState: r.riderState||'',
        riderZip: r.riderZip||'',
        //End Address
        riderEmail: '',
        riderPhone: r.riderPhonenumber||'',
        riderSSN: '',
        riderDOB: '',
        riderNationality: '',
        suspensionStatus: '',
        signatureRider: r.signatureRider||'',
      }));
      const [jinete] = additionalRiders;
      const entry_amount = parseFloat(e.balance||(this.showInfo.entryAmount||'0'));
      const entry_charge = entry_amount * (1+paymentIntent.cardFee);
      const conceptos = (e.conceptos||[]).map((c: any) => ({ ...c, reference: c.name||'', show_id: this.showID, type: 'g' }));
      const classCharges =  (e.classCharges||[]).map((c: any) => ({ ...c, id: null, quantity: '1', total: c.amount }));
      const data = {
        idConcurso: this.showID,
        nombreConcurso: this.showName,
        email: this.email,
        caballo: {
          horseNrha: e.horseNrha||'',
          horseName: e.horseName||'',
          horseFei: e.horseFEI||'',
          horseBreed: '',
          horseSex: '',
          horseHgt: '',
          horseColor: '',
          horseYrFoaled: '',
          horseSire: '',
          horseDam: '',
          horseBreeder: '',
          horseOwner: ''
        },
        jinete,
        additionalRiders,
        idEntrenador: null,
        entrenador: {
          trainerName: e.trainername||'',
          trainerNrha: e.trainerNrha||'',
          trainerLastname: e.trainerLastname||'',
          trainerEmail: '',
          trainerPhone: e.trainerPhonenumber||'',
          //Address
          trainerAddress: e.trainerAddress||'',
          trainerAddress2: e.trainerAddress2||'',
          trainerCountry: e.trainerCountry||'',
          trainerCity: e.trainerCity||'',
          trainerState: e.trainerState||'',
          trainerZip: e.trainerZip||'',
          signatureTrainer: e.signatureTrainer||''
          //End Address
        },
        responsable: {
          payeeNrha: e.payeeNrha||'',
          payeeName: e.payeename||'',
          payeeMiddleName: '',
          payeeLastName: e.payeeLastname||'',
          payeeSSN: '',
          payeePhone: e.payeePhonenumber||'',
          //Address
          payeeAddress: e.payeeAddress||'',
          payeeAddress2: e.payeeAddress2||'',
          payeeCountry: e.payeeCountry||'',
          payeeCity: e.payeeCity||'',
          payeeState: e.payeeState||'',
          payeeZip: e.payeeZip||'',
          //End Address
          payeeIncorporated: ''
        },
        propietario: {
          ownerFEI: e.ownerFEI,
          ownerName: e.ownername,
          ownerLastName: e.ownerLastname,
          ownerNrha: e.ownerNrha,
          ownerPhone: e.ownerPhonenumber,
          ownerMiddleName: '',
          //Address
          ownerAddress: e.ownerAddress||'',
          ownerAddress2: e.ownerAddress2||'',
          ownerCountry: e.ownerCountry||'',
          ownerCity: e.ownerCity||'',
          ownerState: e.ownerState||'',
          ownerZip: e.ownerZip||'',
          signatureOwner: e.signatureOwner||'',
          //End Address
        },
        pruebas: e.pruebas || [],
        cargos: [...conceptos, ...classCharges],
        pago: {
          customer: paymentIntent.customer||'',
          metodo: paymentIntent.method||'tarjeta',
          pagado: paymentIntent.status == 'succeeded',
          notas: paymentIntent.id,
          transactionId: null,
          entry_amount,
          entry_charge,
        },
        estatus: paymentIntent.status == 'succeeded' ? '1' : '0',
        otros: {
          stableWith: e.stablingWith||'',
          arrivalDate: ''
        },
        taxId: e.taxID||'',
        signatureRider: e.signatureRider||'',
        signatureTrainer: e.signatureTrainer||'',
        signatureOwner: e.signatureOwner||'',
        fingerprint: {},
        retryTrace: paymentIntent.id,
        emergencyContact: {
          name: e.emergencyContact||'',
          phone: e.emergencyPhone||''
        }
      };

      return firstValueFrom(this._entriesService.crearSolicitud(data));
    });
    //Await all promises finish
    await Promise.all(promises).then((responses) => {
      //Fin promesas
    }).catch((error) => {
      errorNotification('Error', `Error generating online entry. Please try again later. ${error}`);
      return;
    });
    //Commit the batch and redirect to the home page
    await batch.commit();
    //this.getDrafts();
    this._router.navigate(['/']);
    successNotification('Successful payment', 'The payment has been made successfully.');
  }

  public async handleError(error: string){
    if(error.includes("No such customer: 'cus_")){
      this.actions.StripeFormVisible = false;
      //Remove the customer id from the user document
      const response = await this._cardService.removeStripeCustomerId(this.uid, this.club_id).catch((error: any) => ({ error }));
      if(response.error) return errorNotification('Error', `${response.error.message}`)

      //Create a new customer
      const { customer, reason } = await this._cardService.createStripeCustomer(this.uid, this.showID, this.club_id, this.email).then((r: any) => r).catch((reason: any) => ({ reason }));
      if(reason) return errorNotification('Error', `${reason.error.message}`);
      this.stripeCustomer.id = customer.id||'';
      this.actions.StripeFormVisible = true;
      return;
    }
    return errorNotification('Error', `${error}`);
  }

  private setMetadata(){
    let metadata: any = {
      systemVersion: environment.version
    };
    if(this.paying.length == 0){
      this.metadata = metadata;
      return;
    };
    metadata[`user`] = this.uid;
    this.paying.forEach((entry: any, i: number) => {
      metadata[`entry${i+1}`] = entry.uid||'';
    });
    this.metadata = metadata;
    const [firstEntry] = this.paying;
    if(firstEntry) this.defaultCardHolder = `${firstEntry.trainername||''} ${firstEntry.trainerLastname||''}`;
    this.description = `Show: ${this.showName}, Total online entries: ${this.paying.length}, horses: ${this.paying.map(e => e.horseName).join(', ')}`;
  }

  public sendErrorEmail(title: string, error: any, customer: string) {
    this._emailsService.sendEmail(
      title + ' for ' + customer,
      error
    );
  }
}
