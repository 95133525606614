import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { environment } from 'src/environments/environment';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmailsService {


  constructor(
    private http: HttpClient,
  ) { }

  public async sendEmail(subject: string, body: string, to: string = 'alex@vende.io', html: boolean = false){
    const headers: HttpHeaders = new HttpHeaders({
      'x-api-key': environment.api.ed.key,
      'Content-Type': 'application/json'
    });
    const params: any = {
      to,
      from: 'onlineentries@equestrian.digital',
      subject: subject,
      content: body,
      html
    };

    const response: any = await firstValueFrom(this.http.post(`${environment.api.nest.host}email/send-email/`, params, { headers: headers })).catch(error => ({ error }));
    if(response.error){
      console.error('Error sending email: ', response.error);
    }

    return response;
  }

  public setMailchimpMember(email_address:string, id_concurso: string | number): Observable<any>{
    const headers = new HttpHeaders({ 'x-api-key': environment.api.ed.key, 'Content-Type': 'application/json' });
    const params: any = JSON.stringify({ email_address, id_concurso });
    return this.http.post(`${environment.api.nest.host}mailchimp/members/`, params, { headers: headers });
  }

}
