import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { Observable } from 'rxjs';
import { Firestore, addDoc, getDocs, Timestamp, writeBatch, setDoc, deleteDoc } from '@angular/fire/firestore';
import { collection, doc, query, where } from "firebase/firestore";
import { environment } from './../../environments/environment';
import { EmailsService } from './emails.service';
import { firstValueFrom, map } from 'rxjs';
declare let $: any;

@Injectable({
  providedIn: 'root'
})
export class EntriesService {
  private timezones: any;

  constructor(
    private http: HttpClient,
    private _firestore: Firestore,
    private _emailsService: EmailsService
  ) {
    this.timezones = {
      'America/New_York_DST': 'GMT -0400',
      'America/New_York': 'GMT -0400',
      'America/Chicago': 'GMT -0600',
      'America/Phoenix': 'GMT -0700',
      'America/Denver': 'GMT -0700',
      'America/Los_Angeles': 'GMT -0800'
    };
  }

  public getConcursos() {
    const headers: HttpHeaders = new HttpHeaders({
      'x-api-key': environment.api.ed.key
    });
    return this.http.get(`${environment.api.ed.host}concursos/inscripciones/`, { headers: headers });
  }

  public getPruebas(showID: string): Observable<any> {
    const headers = new HttpHeaders({ 'x-api-key': environment.api.ed.key });
    const params = new HttpParams().set('c', showID).set('n', 'n');
    return this.http.get(`${environment.api.ed.host}pruebas/`, { headers, params });
  }

  public getDivisionsByType(showID: string, type: string): Observable<any> {
    const headers = new HttpHeaders({
      'x-api-key': environment.api.ed.key,
      'Content-Type': 'application/json'
    });
    const params = JSON.stringify({ idConcurso: showID, type: type });
    return this.http.post(`${environment.api.ed.host}divisions/getByType/`, params, { headers: headers });
  }

  public getConceptos(idConcurso: string) {
    const headers: HttpHeaders = new HttpHeaders({
      'x-api-key': environment.api.ed.key,
      'Content-Type': 'application/json'
    });
    const params: any = JSON.stringify({ idConcurso });
    return firstValueFrom(this.http.post(`${environment.api.ed.host}responsables/conceptosOnline/`, params, { headers: headers })).catch(reason => ({ error: true, message: (reason.error||{}).text||(((reason.error||{}).error||{}).message||(reason.message||(reason.error||(reason||'Error during request.')))) }));
  }

  public getPaises() {
    const headers: HttpHeaders = new HttpHeaders({ 'x-api-key': environment.api.ed.key });
    return this.http.get(`${environment.api.ed.host}paises/`, { headers: headers });
  }

  async saveEntryFirebase(data: any, user: any, draftId: string = ''): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        if (draftId) {
          const docRef = doc(this._firestore, 'users', user, 'entries', draftId);
          await setDoc(docRef, data, { merge: true });
        } else{
          const usersReference = collection(this._firestore, 'users', user, 'entries');
          await addDoc(usersReference, data);
        }
      } catch (e: any) {
        return reject({ error: { code: e.errorCode, message: e.message } });
      }
      return resolve('The entry has been registered');
    });
  }

  async getEntries(user: any, showID: string, status?: string): Promise<any> {
    try {
      let documents: any = [];
      const usersReference = collection(this._firestore, `users/${user}/entries`);
      const q = status ? query(usersReference, where('status', '==', status), where('showID', '==', showID)) : query(usersReference, where('showID', '==', showID));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc: any) => {
        const document: any = { ...doc.data(), ref: doc.ref, uid: doc.id };
        documents.push(document)
      });
      return documents;
    } catch (error) {
      console.log(error)
    }
  }

  public getTrainerEntries(idEntrenador: string, idConcurso: string | number) {
    const headers = { 'x-api-key': environment.api.ed.key, 'Content-Type': 'application/json' };
    const params: any = JSON.stringify({ idConcurso, idEntrenador });
    return firstValueFrom(this.http.post(`${environment.api.ed.host}entrenadores/entries/`, params, { headers }).pipe(map((r: any) => ({ entries: r.entries||null, error:r.message||(r.error||null) }))));
  }

  public getShowEntries(idConcurso: string, email: string) {
    const headers = new HttpHeaders({ 'x-api-key': environment.api.ed.key, 'Content-Type': 'application/json' });
    const params: any = JSON.stringify({ idConcurso, email });
    return this.http.post(`${environment.api.ed.host}entrenadores/binomios/`, params, { headers: headers });
  }

  public getConcursoActual() {
    const headers = new HttpHeaders({ 'x-api-key': environment.api.ed.key, 'Content-Type': 'application/json' });
    return this.http.get(`${environment.api.ed.host}concursos/activo/`, { headers: headers });
  }

  public getEntryInfo(idConcurso: any) {
    const headers = new HttpHeaders({ 'x-api-key': environment.api.ed.key, 'Content-Type': 'application/json' });
    const params: any = JSON.stringify({ idConcurso });
    return firstValueFrom(this.http.post(`${environment.api.ed.host}concursos/getEntryInfo/`, params, { headers })).catch(reason => ({ error: true, message: (reason.error||{}).text||(((reason.error||{}).error||{}).message||(reason.message||(reason.error||(reason||'Error during request.')))) }));
  }

  public getSaldo(idConcurso: any, email: string | null) {
    const headers = new HttpHeaders({ 'x-api-key': environment.api.ed.key, 'Content-Type': 'application/json' });
    const params: any = JSON.stringify({ idConcurso, email });
    return this.http.post(`${environment.api.ed.host}entries/saldo/`, params, { headers: headers });
  }

  async deleteDocument(userID: any, documentId: string): Promise<void> {
    try {
      const docRef = doc(this._firestore, `users/${userID}/entries`, documentId);
      await deleteDoc(docRef);
    } catch (error) {
      console.error('Error deleting document:', error);
    }
  }

  public crearSolicitud(data: Solicitud): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      'x-api-key': environment.api.ed.key,
      'Content-Type': 'application/json'
    });
    const params: any = JSON.stringify(data);
    return this.http.post(`${environment.api.nest.host}online-entries/save`, params, { headers: headers });
  }

  public getEntryInvoiceInfo(entry: string, idConcurso: string): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      'x-api-key': environment.api.ed.key,
      'Content-Type': 'application/json'
    });
    const params: any = JSON.stringify({ entry, idConcurso });
    return this.http.post(`${environment.api.ed.host}entries/invoice/info/`, params, { headers: headers });
  }

  public getEntryInvoiceCharges(entry: string, idConcurso: string): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      'x-api-key': environment.api.ed.key,
      'Content-Type': 'application/json'
    });
    const params: any = JSON.stringify({ entry, idConcurso });
    return this.http.post(`${environment.api.ed.host}entries/invoice/charges/`, params, { headers: headers });
  }

  public getEntryInvoiceFeedOrders(entry: string, idConcurso: string): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      'x-api-key': environment.api.ed.key,
      'Content-Type': 'application/json'
    });
    const params: any = JSON.stringify({ entry, idConcurso });
    return this.http.post(`${environment.api.ed.host}orders/resumen/`, params, { headers: headers });
  }

  public getEntryInvoiceSplits(entry: string, idConcurso: string): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      'x-api-key': environment.api.ed.key,
      'Content-Type': 'application/json'
    });
    const params: any = JSON.stringify({ entry, idConcurso });
    return this.http.post(`${environment.api.ed.host}entries/invoice/splits/`, params, { headers: headers });
  }

  public getEntryInvoicePayments(entry: string, idConcurso: string): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      'x-api-key': environment.api.ed.key,
      'Content-Type': 'application/json'
    });
    const params: any = JSON.stringify({ entry, idConcurso });
    return this.http.post(`${environment.api.ed.host}entries/invoice/payments/`, params, { headers: headers });
  }

  public getEnteredClasses(idBinomio: string): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      'x-api-key': environment.api.ed.key,
      'Content-Type': 'application/json'
    });
    const params: any = JSON.stringify({ idBinomio });
    return this.http.post(`${environment.api.ed.host}app/entries/enteredClasses/`, params, { headers: headers });
  }

  public addDropClass(idBinomio: string, idUsuario: string, ipc: string): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      'x-api-key': environment.api.ed.key,
      'Content-Type': 'application/json'
    });
    const params: any = JSON.stringify({ idBinomio, idUsuario, ipc, remoto: 1 });
    return this.http.post(`${environment.api.ed.host}entries/inscribir/`, params, { headers: headers });
  }

  public async updateEntries(idConcurso: string, ipc: any = false) {
    let params: any = { id: idConcurso };
    if (ipc) params['ipc'] = ipc;
    const binomiosRequest = await this.http.get<any>(`${environment.api.ed.host}actualizar/resultados/index3.php`, { params }).toPromise();
    if (binomiosRequest.error) {
      $.NotificationApp.send("Error", 'Please try again.', 'bottom-right', '#fa5c7c', 'error');
      return;
    }
    let batch = writeBatch(this._firestore);
    let actualizar = false;
    let i = 0;
    //Binomios
    for (let binomio of binomiosRequest.binomios) {
      actualizar = true;
      batch.set(doc(this._firestore, 'resultados', `us_${binomio.ipc}`, 'resultados', `${binomio.id_binomio}`), binomio, { merge: true });
      if (++i == 450) {
        await batch.commit();
        i = 0;
        batch = writeBatch(this._firestore);
      }
      const binomioNuevo = this.parseNewInterface(binomio);
      batch.set(doc(this._firestore, 'results', `us_${binomio.ipc}_${binomio.id_binomio.replace('us_', '')}`), binomioNuevo, { merge: true });
      if (++i == 450) {
        await batch.commit();
        i = 0;
        batch = writeBatch(this._firestore);
      }
    }
    //Recesos
    for (let receso of binomiosRequest.recesos) {
      actualizar = true;
      batch.set(doc(this._firestore, 'resultados', `us_${receso.ipc}`, 'resultados', `${receso.id}`), receso, { merge: true });
      if (++i == 450) {
        await batch.commit();
        i = 0;
        batch = writeBatch(this._firestore);
      }
    }
    //Participantes en prueba
    for (let participantes of binomiosRequest.inscritosCalificados) {
      batch.set(doc(this._firestore, 'concursos', `us_${idConcurso}`, 'pruebas', `${participantes.ipc}`), participantes, { merge: true });
      if (++i == 450) {
        await batch.commit();
        i = 0;
        batch = writeBatch(this._firestore);
      }
    }
    batch.commit();
  }

  private parseNewInterface(binomio: any): any {
    if (binomio.inicio && binomio.inicio != '0000-00-00 00:00:00') {
      binomio.inicio = Timestamp.fromDate(new Date(`${binomio.inicio.replace(/-/g, '/')} ${this.timezones[binomio.timezone] ? this.timezones[binomio.timezone] : ''}`.trim()));
    }

    let b: any = { ...binomio };
    b.accumulated = binomio.acumulado || '';
    b.classDate = binomio.inicio || (new Date());
    b.className = binomio.nombrePrueba || '';
    b.classified = binomio.clasificado == '1';
    b.currentRank = binomio.rankTabla || `${binomio.posicion}`;
    b.entryNumber = binomio.cucarda || '';
    b.faults = binomio.faltas || '';
    //TODO: definir de donde va a salir esta info
    b.faultsDetail = '';
    b.horseId = binomio.id_caballo || '';
    b.horseInfo = binomio.datosCaballo || '';
    b.horseName = binomio.caballo || '';
    b.id = binomio.id_binomio;
    b.inRing = binomio.en_pista == '1';
    b.ipc = `us_${binomio.ipc}`;

    b.order = binomio.order || 0;
    b.previous = (binomio.anterior || '0') == '1';
    b.prize = binomio.premio || '';
    b.rank = binomio.posicion || '';
    if (binomio.result1) b.result1 = binomio.result1 || '';
    if (binomio.result2) b.result2 = binomio.result2 || '';
    if (binomio.result3) b.result3 = binomio.result3 || '';
    b.riderAcronym = binomio.siglas || '';
    b.riderBadge = binomio.logo_url || '';
    b.riderBadgeDark = binomio.logoDark || '';
    b.riderCategory = binomio.categoria || '';
    b.riderId = binomio.id_jinete || '';
    b.riderName = binomio.jinete || '';
    b.riderPhoto = binomio.foto || '';
    b.riderShortName = binomio.jinetePantalla || '';
    b.showName = sessionStorage.getItem('nombreConcurso') || '';
    b.startTime = binomio.startTime || '';
    b.time = binomio.tiempo || '';
    b.video1 = binomio.bloqueo1 != '1';
    b.video2 = binomio.bloqueo2 != '1';
    b.video3 = false;
    b.video1Url = '';//binomio.video_app||'';
    b.video2Url = '';//binomio.video_app_2||'';
    b.video3Url = '';
    b.onlyOtomi = false;

    return b;
  }

  public getAvailableDays(idBinomio: string): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      'x-api-key': environment.api.ed.key,
      'Content-Type': 'application/json'
    });
    const params: any = JSON.stringify({ idBinomio });
    return this.http.post(`${environment.api.ed.host}app/entries/availableDays/`, params, { headers: headers });
  }

  public getAvailableClasses(idBinomio: string, searchText: string, selectedDay: string): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      'x-api-key': environment.api.ed.key,
      'Content-Type': 'application/json'
    });
    const params: any = JSON.stringify({ idBinomio, selectedDay, searchText: searchText.replace("'", "\\'") });
    return this.http.post(`${environment.api.ed.host}app/entries/availableClasses/`, params, { headers: headers });
  }

  public getConcurso(idConcurso: string): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      'x-api-key': environment.api.ed.key,
      'Content-Type': 'application/json'
    });
    const params: any = { c: idConcurso };
    return this.http.get(`${environment.api.ed.host}concursos/getById/`, { headers, params });
  }

  public reservacionesPorEntrenador(idEntrenador: string, idConcurso: string): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      'x-api-key': environment.api.ed.key,
      'Content-Type': 'application/json'
    });
    const params: any = JSON.stringify({ idEntrenador, idConcurso });
    return this.http.post(`${environment.api.ed.host}inscripciones-online/reservaciones-por-entrenador/`, params, { headers: headers });
  }

  public addCard(card: any): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      'x-api-key': environment.api.ed.key,
      'Content-Type': 'application/json'
    });
    const params: any = JSON.stringify(card);
    return this.http.post(`${environment.api.ed.host}chase/save-card/`, params, { headers: headers });
  }

  public solicitudTrainerReservation(idEntrenador: string, idConcurso: string, cargos: any[], email: string, nombreConcurso: string): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      'x-api-key': environment.api.ed.key,
      'Content-Type': 'application/json'
    });
    const params: any = JSON.stringify({ idEntrenador, idConcurso, cargos, email, nombreConcurso });
    return this.http.post(`${environment.api.ed.host}inscripciones-online/solicitud-trainer-reservation/`, params, { headers: headers });
  }

  public getAllConcepts(idConcurso: string): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      'x-api-key': environment.api.ed.key,
      'Content-Type': 'application/json'
    });
    const params: any = { c: idConcurso };
    return this.http.get(`${environment.api.ed.host}conceptos/`, { headers, params });
  }

  public getSolitudesPorMail(email: string, idConcurso: string): Promise<any> {
    const headers: HttpHeaders = new HttpHeaders({
      'x-api-key': environment.api.ed.key,
      'Content-Type': 'application/json'
    });
    const params: any = JSON.stringify({ email, idConcurso });
    return firstValueFrom(this.http.post(`${environment.api.ed.host}inscripciones-online/solicitudes-por-email/`, params, { headers: headers })).catch(reason => ({ error: true, message: (reason.error||{}).text||(((reason.error||{}).error||{}).message||(reason.message||(reason.error||(reason||'Error during request.')))) }));
  }
}

declare global {
  interface Solicitud {
    idConcurso: string,
    nombreConcurso: string,
    email: string,
    caballo: any,
    jinete: any
    idEntrenador: string | null,
    entrenador: any,
    responsable: any,
    propietario: any,
    pruebas: any,
    cargos: any,
    pago: any,
    estatus: any,
    otros: any,
    taxId: any,
    signatureRider: any,
    signatureTrainer: any,
    signatureOwner: any,
    fingerprint: any,
    retryTrace: any,
    emergencyContact: any
  }
}

