<div class="wrapper">
  <app-top-menu></app-top-menu>
  <app-left-menu></app-left-menu>
  <div class="content-page">
    <div class="container-fluid">
      <div id="agreementModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="agreementModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-full-width">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title" id="agreementModalLabel">FEDERATION ENTRY AGREEMENT</h4>
              <button type="button" class="btn close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body">
              <p>By entering a Federation-licensed Competition and signing this entry blank as the Owner, Lessee,
                Trainer, Manager, Agent, Coach, Driver, Rider, Handler,
                Vaulter or Longeur and on behalf of myself and my principals, representatives, employees and agents, I
                agree that I am subject to the Bylaws and Rules
                of The United States Equestrian Federation, Inc. (the “Federation”) and the local rules of
                (Competition). I agree to be
                bound by the Bylaws and Rules of the Federation and of the competition. I will accept as final the
                decision of the Hearing Committee on any question arising
                under the Rules, and agree to release and hold harmless the competition, the Federation, their
                officials, directors and employees for any action taken under
                the Rules. I represent that I am eligible to enter and/or participate under the Rules, and every horse
                I am entering is eligible as entered. I also agree that as
                a condition of and in consideration of acceptance of entry, the Federation and/or the Competition may
                use or assign photographs, videos, audios, cable -
                casts, broadcasts, internet, film, new media or other likenesses of me and my horse taken during the
                course of the competition for the promotion, coverage
                or benefit of the competition, sport, or the Federation. Those likenesses shall not be used to
                advertise a product and they may not be used in such a way as
                to jeopardize amateur status. I hereby expressly and irrevocably waive and release any rights in
                connection with such use, including any claim to compensation, invasion of privacy, right of
                publicity, or to misappropriation. The construction and application of Federation rules are governed
                by the laws of the State
                of New York, and any action instituted against the Federation must be filed in New York State. See
                GR908.4.</p>
              <p>Federation Release,
                Assumption of Risk, Waiver, and Indemnification This document waives important legal rights. Read it
                carefully before signing. This document waives
                important legal rights. Read it carefully before signing. I AGREE in consideration for my
                participation in this Competition to the following: I AGREE that
                the “Federation” and “Competition” as used herein includes the Licensee and Competition Management,
                as well as all of their officials, officers, directors,
                employees, agents, personnel, volunteers and Federation affiliates.I AGREE that I choose to
                participate voluntarily in the Competition with my horse, as a
                rider, driver, handler, vaulter, longeur, lessee, owner, agent, coach, trainer, or as parent or
                guardian of a junior exhibitor. I am fully aware and acknowledge
                that horse sports and the Competition involve inherent dangerous risks of accident, loss, and serious
                bodily injury including broken bones, head injuries,
                trauma, pain, suffering, or death. (“Harm”). I AGREE to hold harmless and release the Federation and
                the Competition from all claims for money
                damages or otherwise for any Harm to me or my horse and for any Harm of any nature caused by me or my
                horse to others, even if the Harm arises
                or results, directly or indirectly, from the negligence of the Federation or the Competition. I AGREE
                to expressly assume all risks of Harm to me or my
                horse, including Harm resulting from the negligence of the Federation or the Competition. I AGREE to
                indemnify (that is, to pay any losses, damages, or
                costs incurred by) the Federation and the Competition and to hold them harmless with respect to
                claims for Harm to me or my horse, and for claims
                made by others for any Harm caused by me or my horse while at the Competition. I have read the
                Federation Rules about protective equipment,
                including GR801 and, if applicable, EV114, and I understand that I am entitled to wear protective
                equipment without penalty, and I acknowledge that the
                Federation strongly encourages me to do so while WARNING that no protective equipment can guard
                against all injuries. If I am a parent or guardian of
                a junior exhibitor, I consent to the child’s participation and AGREE to all of the above provisions
                and AGREE to assume all of the obligations of this
                Release on the child’s behalf I represent that I have the requisite training, coaching and abilities
                to safely compete in this competition. I AGREE that if I
                am injured at this competition, the medical personnel treating my injuries may provide information on
                my injury and treatment to the Federation on the
                official USEF accident/injury report form. BY SIGNING BELOW, I AGREE to be bound by all applicable
                Federation Rules and all terms and provisions of
                this entry blank and all terms and provisions of this Prize List. If I am signing and submitting this
                Agreement electronically, I acknowledge that my
                electronic signature shall have the same validity, force and effect as if I affixed my signature by
                my own hand.</p>
              <p>The entire Split Rock Jumping Tour competition must be conducted in accordance with FEI rules and
                applicable USEF rules. Entry into the competition
                represents acknowledgement and acceptance, with no appeal, to these competition rules for all
                classes.</p>
              <p><b>BY SIGNING BELOW, I AGREE</b> that I have read, understand, and agree to be bound by all
                applicable Federation Bylaws, rules, and policies including the
                USEF Safe Sport Policy and Minor Athlete Abuse Prevention Policies (MAAPP) as published at
                www.usef.org, as amended from time to time, as well as all
                terms and provisions of this Prize List. If I am signing and submitting this Agreement electronically,
                I acknowledge that my electronic signature shall
                have the same validity, force and effect as if I affixed my signature by my own hand.</p>

              <h4 class="modal-title mb-3">WAIVER AND RELEASE OF LIABILITY, ASSUMPTION OF RISK AND INDEMNITY AGREEMENT
              </h4>
              <p>For and in consideration of United States Equestrian Federation, Inc. dba US Equestrian (“USEF”)
                allowing me, the undersigned, to participate in any
                capacity (including as a rider, driver, handler, vaulter, longeur, lessee, owner, agent, coach,
                official, trainer or volunteer) in a USEF sanctioned, licensed or
                approved event or activity, including but not limited to equestrian clinics, practices, shows,
                competitions and related or incidental activities and
                ________________________________(<b>“USEF Event”</b> or <b>“USEF Events”</b>); I, for myself, and on
                behalf of my spouse, children, heirs and next of kin,
                and any legal and personal representatives, executors, administrators, successors,, and assigns, hereby
                agree to and make the following contractual repre- sentations pursuant to this Agreement (the
                “Agreement”):</p>
              <p>
                A. RULES AND REGULATIONS: I hereby agree that I have read, understand, and agree to be bound by all
                applicable Federation Bylaws, rules, and policies
                including the USEF Safe Sport Policy and Minor Athlete Abuse Prevention Policies (MAAPP) as published at
                www.usef.org, as amended from time to time.
              </p>
              <p>B. ACKNOWLEDGMENT OF RISK: I knowingly, willingly, and voluntarily acknowledge the inherent risks
                associated with the sport of equestrian and know that
                horseback riding and related equestrian activities are inherently dangerous, and that participation in
                any USEF Event involves risks and dangers including, without limitation, the potential for serious
                bodily injury (including broken bones, head or neck injuries), sickness and disease (including
                communicable diseases),
                trauma, pain & suffering, permanent disability, paralysis and death; loss of or damage to personal
                property (including my mount & equipment) arising out of the
                unpredictable behavior of horses; exposure to extreme conditions and circumstances; accidents involving
                other participants, eventstaff, volunteersor spectators; contact orcollision with other participants and
                horses, natural or manmadeobjects; adverseweather conditions; facilities issues and premises conditions;
                failure of protective equipment (including helmets); inadequate safety measures; participants of varying
                skill levels; situations beyond the immediate control of
                the USEF Event organizers and competition management; and other undefined, not readily foreseeable and
                presently unknown risks and dangers <b>(“Risks”)</b>.
              </p>
              <p class="text-center"><b>EQUINE ACTIVITY LIABILITY ACT WARNING:</b><br>
                CAUTION: HORSEBACK RIDING AND EQUINE ACTIVITIES CAN BE DANGEROUS. RIDE AT YOUR OWN RISK.<br>
                <b>Under the laws of most States, an equine activity sponsor or equine professional is not liable for
                  any injury to, or the death of, a participant in
                  equine activities resulting from the inherent risks of equine activities.</b>
              </p>
              <p>C. ASSUMPTION OF RISK: I understand that the aforementioned Risks may be caused in whole or in part or
                result directly or indirectly from the negligence
                of my own actions or inactions, the actions or inactions of others participating in the USEF Events, or
                the negligent acts or omissions of the Released Parties defined below, and I hereby voluntarily and
                knowingly assume all such Risks and responsibility for any damages, liabilities, losses, or expenses
                that I
                incur as a result of my participation in any USEF Events. I also agree to be responsible for any injury
                or damage caused by me, my horse, my employees or
                contractors under my direction and control at any USEF Event.</p>
              <p>D. WAIVER AND RELEASE OF LIABILITY, HOLD HARMLESS AND INDEMNITY: In conjunction with my participation
                in any USEF Event, I hereby release, waive
                and covenant not to sue, and further agree to indemnify, defend and hold harmless the following parties:
                USEF, USEF Recognized Affiliate Associations,
                the United States Olympic & Paralympic Committee (USOPC), USEF clubs, members, Event participants
                (including athletes/riders, coaches, trainers, judges/officials, and other personnel), the Event owner,
                licensee, and competition managers; the promoters, sponsors, or advertisers of any USEF Event; any
                charity or other beneficiary which may benefit from the USEF Event; the owners, managers, or lessors of
                any facilities or premises where a USEF Event may
                be held; and all directors, officers, employees, agents, contractors, and volunteers of any of the
                aforementioned parties <b>(Individually and Collectively, the
                  “Released Parties” or “Event Organizers”)</b>, with respect to any liability, claim(s), demand(s),
                cause(s) of action, damage(s), loss, or expense (including
                court costs and reasonable attorney fees) of any kind or nature <b>(“Liability”)</b> which may arise out
                of, result from, or relate in any way to my participation in
                the USEF Events, including claims for Liability caused in whole or in part by the negligent acts or
                omissions of the Released Parties.</p>
              <p>E. COMPLETE AGREEMENT AND SEVERABILITY CLAUSE: This Agreement represents the complete understanding
                between the parties regarding these issues
                and no oral representations, statements or inducements have been made apart from this Agreement. If any
                provision of this Agreement is held to be
                unlawful, void, or for any reason unenforceable, then that provision shall be deemed severable from this
                Agreement and shall not affect the validity and
                enforceability of any remaining provisions</p>
              <p><b>I HAVE CAREFULLY READ THIS DOCUMENT IN ITS ENTIRETY, UNDERSTAND ALL OF ITS TERMS AND CONDITIONS, AND
                  KNOW IT CONTAINS AN ASSUMPTION
                  OF RISK, RELEASE AND WAIVER FROM LIABILITY, AS WELL AS A HOLD HARMLESS AND INDEMNIFICATION
                  OBLIGATIONS.</b></p>
              <p>By signing below, I (as the participant or as the Parent/Legal Guardian of the minor identified below)
                hereby accept and agree to the terms and conditions of this Agreement in connection with my (or the
                minor’s) participation in any USEF Event. If, despite this Agreement, I, or anyone on my behalf or the
                minor’s behalf, makes a claim for Liability against any of the Released Parties, I will indemnify,
                defend and hold harmless each of the Released Parties
                from any such Liabilities as the result of such claim.</p>
              <p><b>The parties agree that this agreement may be electronically signed. The parties agree that the
                  electronic signatures appearing on this agreement
                  are the same as handwritten signatures for the purposes of validity, enforceability, and
                  admissibility.</b></p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-light" data-dismiss="modal" (click)="hideModal()">Close</button>
            </div>
          </div><!-- /.modal-content -->
        </div><!-- /.modal-dialog -->
      </div>

      <div id="formModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="standard-modalLabel"
        aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title" id="standard-modalLabel">New {{ actions.modalForm }}</h4>
              <button type="button" class=" btn close" data-bs-dismiss="modal" aria-hidden="true">X</button>
            </div>
            <div class="modal-body">

              <p *ngIf="!reining"> <strong>Suggestions:</strong> Type in USEF number, then hit tab or enter. Correct or add information as needed</p>

              <p *ngIf="reining"> <strong>Suggestions:</strong> Type in NRHA number, then hit tab or enter. Correct or add information as needed</p>

              <div class="col-12" *ngIf="actions.modalForm == 'trainer'">
                <form class="row row-cols-lg-12 col-12 g-3 align-items-center needs-validation"
                  [formGroup]="trainerForm" autocomplete="off" (ngSubmit)="saveSuggestions($event)">
                  <div class="col-sm-12 col-md-6">
                    <div class="input-group flex-nowrap">
                      <span class="input-group-text" *ngIf="loadingUsef">
                        <div class="spinner-border spinner-border-sm" role="status"></div>
                      </span>
                      <input (change)="getPersonByUSEF($any($event).target.value, 'trainer')" type="text" class="form-control" id="floatingUSEFTrainer" placeholder="USEF #" formControlName="USEF" />
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-6">
                    <input type="text" class="form-control" id="floatingTrainerfirsname" placeholder="Firstname"
                      formControlName="firstname" required />
                  </div>

                  <div class="col-sm-12 col-md-6">
                    <input type="text" class="form-control" id="floatingTrainerlastname" placeholder="Lastname"
                      formControlName="lastname" required />
                  </div>

                  <div class="col-sm-12 col-md-6">
                    <input type="text" class="form-control" id="floatingTrainerPhoneNumber" placeholder="Phone number"
                      formControlName="phonenumber" required />
                  </div>

                  <!-- Address -->
                  <div class="col-sm-12 col-md-12">
                    <!-- <autocomplete-addresss (setAddress)="getAddress($event, 'trainer')"
                      adressType="geocode"></autocomplete-addresss> -->
                      <input type="text" class="form-control" placeholder="Address" formControlName="address">
                  </div>

                  <div class="col-sm-12 col-md-12">
                    <input type="text" class="form-control" placeholder="Address2" formControlName="address2" />
                  </div>

                  <div class="col-sm-12 col-md-6">
                    <input type="text" class="form-control" placeholder="City" formControlName="city" required="" />
                  </div>

                  <div class="col-sm-12 col-md-6">
                    <input type="text" class="form-control" placeholder="State" formControlName="state" required="" />
                  </div>

                  <div class="col-sm-12 col-md-6">
                    <input type="text" class="form-control" placeholder="Zip" formControlName="zip" required="" />
                  </div>

                  <div class="col-sm-12 col-md-6">
                    <input type="text" class="form-control" placeholder="Country" formControlName="country"
                      required="" />
                  </div>
                  <!-- End Address -->

                  <div class="modal-footer">
                    <button type="button" class="btn btn-light" data-bs-dismiss="modal">Close</button>
                    <button type="submit" class="btn btn-primary">Save & select</button>
                  </div>
                </form>
              </div>

              <div class="col-12" *ngIf="actions.modalForm == 'horse'">
                <form class="row row-cols-lg-12 g-3 align-items-center needs-validation" [formGroup]="horseForm"
                  autocomplete="off" (ngSubmit)="saveSuggestions($event)">
                  <div class="col-sm-12 col-md-6" *ngIf="!reining">
                    <div class="input-group flex-nowrap">
                      <span class="input-group-text" *ngIf="loadingUsef">
                        <div class="spinner-border spinner-border-sm" role="status"></div>
                      </span>
                      <input (change)="getHorseByUSEF()" type="text" class="form-control" id="floatingUSEF" placeholder="USEF #" formControlName="USEF" />
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-6" *ngIf="!reining">
                    <div class="input-group flex-nowrap">
                      <span class="input-group-text" *ngIf="loadingFei">
                        <div class="spinner-border spinner-border-sm" role="status"></div>
                      </span>
                      <input (change)="getHorseByFEI()" type="text" class="form-control" id="floatingFEI" placeholder="FEI Number" formControlName="FEI" />
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-6">
                    <input type="text" class="form-control" id="floatingHorse" placeholder="Horse name"
                      formControlName="name" required />
                  </div>


                  <div class="modal-footer">
                    <button type="button" class="btn btn-light" data-bs-dismiss="modal">Close</button>
                    <button type="submit" class="btn btn-primary">Save & select</button>
                  </div>
                </form>
              </div>

              <div class="col-12" *ngIf="actions.modalForm == 'owner'">
                <form class="row row-cols-lg-12 g-3 align-items-center needs-validation" [formGroup]="ownerForm"
                  autocomplete="off" (ngSubmit)="saveSuggestions($event)">
                  <div class="col-sm-12 col-md-6">
                    <div class="input-group flex-nowrap">
                      <span class="input-group-text" *ngIf="loadingUsef">
                        <div class="spinner-border spinner-border-sm" role="status"></div>
                      </span>
                      <input (change)="getPersonByUSEF($any($event).target.value, 'owner')" type="text" class="form-control" id="floatingUSEFOwner" placeholder="USEF #" formControlName="USEF" />
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-6">
                    <input type="text" class="form-control" id="floatingFEIOwner" placeholder="FEI Number"
                      formControlName="FEI" />
                  </div>

                  <div class="col-sm-12 col-md-6">
                    <input type="text" class="form-control" id="floatingOwnerfirsname" placeholder="Firstname"
                      formControlName="firstname" required />
                  </div>

                  <div class="col-sm-12 col-md-6">
                    <input type="text" class="form-control" id="floatingOwnerlastname" placeholder="Lastname"
                      formControlName="lastname" required />
                  </div>

                  <div class="col-sm-12 col-md-6">
                    <input type="text" class="form-control" id="floatingOwnerphonenumber" placeholder="Phone number"
                      formControlName="phonenumber" required />
                  </div>

                  <!-- Address -->
                  <div class="col-sm-12 col-md-12">
                    <!-- <autocomplete-addresss (setAddress)="getAddress($event, 'owner')"
                      adressType="geocode"></autocomplete-addresss> -->
                      <input type="text" class="form-control" placeholder="Address" formControlName="address">

                  </div>

                  <div class="col-sm-12 col-md-12">
                    <input type="text" class="form-control" placeholder="Address2" formControlName="address2" />
                  </div>

                  <div class="col-sm-12 col-md-6">
                    <input type="text" class="form-control" placeholder="City" formControlName="city" required>
                  </div>

                  <div class="col-sm-12 col-md-6">
                    <input type="text" class="form-control" placeholder="State" formControlName="state" required>
                  </div>

                  <div class="col-sm-12 col-md-6">
                    <input type="text" class="form-control" placeholder="Zip" formControlName="zip" required>
                  </div>

                  <div class="col-sm-12 col-md-6">
                    <input type="text" class="form-control" placeholder="Country" formControlName="country" required>
                  </div>
                  <!-- End Address -->

                  <div class="modal-footer">
                    <button type="button" class="btn btn-light" data-bs-dismiss="modal">Close</button>
                    <button type="submit" class="btn btn-primary">Save & select</button>
                  </div>
                </form>
              </div>

              <div class="col-12" *ngIf="actions.modalForm == 'rider'">
                <form class="row row-cols-lg-12 g-3 align-items-center needs-validation" [formGroup]="riderForm" autocomplete="off" (ngSubmit)="saveSuggestions($event)">
                  <div class="col-sm-12 col-md-6">
                    <div class="input-group flex-nowrap">
                      <span class="input-group-text" *ngIf="loadingUsef">
                        <div class="spinner-border spinner-border-sm" role="status"></div>
                      </span>
                      <input (change)="getPersonByUSEF($any($event).target.value, 'rider')" class="form-control" id="floatingUSEFRider" placeholder="USEF #" formControlName="USEF" />
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <div class="input-group flex-nowrap">
                      <span class="input-group-text" *ngIf="loadingFei">
                        <div class="spinner-border spinner-border-sm" role="status"></div>
                      </span>
                      <input class="form-control" id="floatingFEIRider" placeholder="FEI Number" formControlName="FEI" (change)="getPersonByFEI()" />
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <input class="form-control" id="floatingRiderfirsname" placeholder="Firstname" formControlName="firstname" required />
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <input class="form-control" id="floatingRiderlastname" placeholder="Lastname" formControlName="lastname" required />
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <input class="form-control" id="floatingRiderphonenumber" placeholder="Phone number" formControlName="phonenumber" required />
                  </div>

                  <!-- Address -->
                  <div class="col-sm-12 col-md-12">
                    <!-- <autocomplete-addresss (setAddress)="getAddress($event, 'rider')"
                      adressType="geocode"></autocomplete-addresss> -->
                      <input type="text" class="form-control" placeholder="Address" formControlName="address">

                  </div>

                  <div class="col-sm-12 col-md-12">
                    <input type="text" class="form-control" placeholder="Address2" formControlName="address2" />
                  </div>

                  <div class="col-sm-12 col-md-6">
                    <input type="text" class="form-control" placeholder="City" formControlName="city" required="" />
                  </div>

                  <div class="col-sm-12 col-md-6">
                    <input type="text" class="form-control" placeholder="State" formControlName="state" required="" />
                  </div>

                  <div class="col-sm-12 col-md-6">
                    <input type="text" class="form-control" placeholder="Zip" formControlName="zip" required="" />
                  </div>

                  <div class="col-sm-12 col-md-6">
                    <input type="text" class="form-control" placeholder="Country" formControlName="country"
                      required="" />
                  </div>
                  <!-- End Address -->

                  <div class="modal-footer">
                    <button type="button" class="btn btn-light" data-bs-dismiss="modal">Close</button>
                    <button type="submit" class="btn btn-primary">Save & select</button>
                  </div>
                </form>
              </div>

              <div class="col-12" *ngIf="actions.modalForm == 'payee'">
                <form class="row row-cols-lg-12 g-3 align-items-center needs-validation" [formGroup]="payeeForm"
                  autocomplete="off" (ngSubmit)="saveSuggestions($event)">
                  <div class="col-sm-12 col-md-6">
                    <div class="input-group flex-nowrap">
                      <span class="input-group-text" *ngIf="loadingUsef">
                        <div class="spinner-border spinner-border-sm" role="status"></div>
                      </span>
                      <input (change)="getPersonByUSEF($any($event).target.value, 'payee')" type="text" class="form-control" id="floatingUSEFPayee" placeholder="USEF #" formControlName="USEF" />
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <input type="text" class="form-control" id="floatingPayeefirsname" placeholder="Firstname"
                      formControlName="firstname" required />
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <input type="text" class="form-control" id="floatingPayeelastname" placeholder="Lastname"
                      formControlName="lastname" required />
                  </div>

                  <div class="col-sm-12 col-md-6">
                    <input type="text" class="form-control" id="floatingPayeephonenumber" placeholder="Phone number"
                      formControlName="phonenumber" required />
                  </div>

                  <!-- Address -->
                  <div class="col-sm-12 col-md-12">
                    <!-- <autocomplete-addresss (setAddress)="getAddress($event, 'payee')"
                      adressType="geocode"></autocomplete-addresss> -->
                      <input type="text" class="form-control" placeholder="Address" formControlName="address">

                  </div>

                  <div class="col-sm-12 col-md-12">
                    <input type="text" class="form-control" placeholder="Address2" formControlName="address2" />
                  </div>

                  <div class="col-sm-12 col-md-6">
                    <input type="text" class="form-control" placeholder="City" formControlName="city" required="" />
                  </div>

                  <div class="col-sm-12 col-md-6">
                    <input type="text" class="form-control" placeholder="State" formControlName="state" required="" />
                  </div>

                  <div class="col-sm-12 col-md-6">
                    <input type="text" class="form-control" placeholder="Zip" formControlName="zip" required="" />
                  </div>

                  <div class="col-sm-12 col-md-6">
                    <input type="text" class="form-control" placeholder="Country" formControlName="country"
                      required="" />
                  </div>
                  <!-- End Address -->

                  <div class="modal-footer">
                    <button type="button" class="btn btn-light" data-bs-dismiss="modal">Close</button>
                    <button type="submit" class="btn btn-primary">Save & select</button>
                  </div>
                </form>
              </div>
            </div>


          </div><!-- /.modal-content -->
        </div><!-- /.modal-dialog -->
      </div><!-- /.modal -->

      <div>
        <div class="mt-3">
          <div class="row">

            <div class="col-md-6 col-sm-12">
              <div class="card card-body">
                <div class="col-12">
                  <form class="form-horizontal">
                    <h5>ENTRY DATA</h5>
                    <div class="row mb-2">
                      <div class="col-sm-12 col-md-2">
                        <label for="trainer-dropdown" class="col-form-label col-form-label-sm">Trainer <span
                            class="text-danger">*</span></label>
                      </div>
                      <div class="col-sm-12 col-md-8">
                        <select id="trainer-dropdown" class="form-select form-control-sm" [(ngModel)]="selects.trainer" [ngModelOptions]="{standalone: true}">
                          <option selected disabled value="">Select a trainer</option>
                          <option *ngFor="let trainer of suggestions.trainer" value="{{ trainer.uid }}">{{ trainer.firstname }} {{ trainer.lastname }} ({{ trainer.USEF }})</option>
                        </select>
                      </div>
                      <div class="col-sm-12 col-md-2">
                        <div class="d-grid">
                          <button class="btn btn-success btn-sm" type="button" (click)="newEntity('trainer')">new
                            +</button>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-2">
                      <div class="col-sm-12 col-md-2">
                        <label for="horse-dropdown" class="col-form-label col-form-label-sm">Horse <span
                            class="text-danger">*</span></label>
                      </div>
                      <div class="col-sm-12 col-md-8">
                        <select id="horse-dropdown" class="form-select form-control-sm" [(ngModel)]="selects.horse" [ngModelOptions]="{standalone: true}">
                          <option selected disabled value="">Select a horse</option>
                          <option *ngFor="let horse of suggestions.horse" [value]="horse?.uid">{{ horse?.name }} ({{ horse?.USEF }})</option>
                        </select>
                      </div>
                      <div class="col-sm-12 col-md-2">
                        <div class="d-grid">
                          <button class="btn btn-success btn-sm" type="button" (click)="newEntity('horse')">new
                            +</button>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-2">
                      <div class="col-sm-12 col-md-2">
                        <label for="rider-dropdown" class=" col-form-label col-form-label-sm">Rider(s) <span
                            class="text-danger">*</span></label>
                      </div>
                      <div class="col-sm-12 col-md-8">
                        <select id="rider-dropdown" class="form-select form-control-sm" [(ngModel)]="selects.rider" [ngModelOptions]="{standalone: true}" (change)="addRider($any($event).target.value)">
                          <option value="">Select a rider</option>
                          <option *ngFor="let rider of suggestions?.rider" [value]="rider?.uid">{{ rider?.firstname }} {{ rider?.lastname }} ({{ rider?.USEF }})</option>
                        </select>
                      </div>
                      <div class="col-sm-12 col-md-2">
                        <div class="d-grid">
                          <button class="btn btn-success btn-sm" type="button" (click)="newEntity('rider')">new +</button>
                        </div>
                      </div>
                    </div>

                    <div class="row justify-content-center" *ngIf="selects.riders.length">
                      <div class="mb-2 col-sm-12 col-md-8">
                        <ul class="list-group">
                          <li class="list-group-item py-1 px-2" *ngFor="let r of selects.riders">{{ r?.firstname }} {{ r?.lastname }} <span *ngIf="r?.USEF">({{ r?.USEF }})</span></li>
                        </ul>
                      </div>
                    </div>

                    <div class="row mb-2">
                      <div class="col-sm-12 col-md-2">
                        <label for="owner-dropdown" class="col-form-label col-form-label-sm">Owner <span
                            class="text-danger">*</span></label>
                      </div>
                      <div class="col-sm-12 col-md-8">
                        <select id="owner-dropdown" class="form-select form-control-sm" [(ngModel)]="selects.owner" [ngModelOptions]="{standalone: true}">
                          <option selected disabled value="">Select an Owner</option>
                          <option *ngFor="let owner of suggestions.owner" [value]="owner?.uid">{{ owner?.firstname }} {{ owner?.lastname }} ({{ owner?.USEF }})</option>
                        </select>
                      </div>
                      <div class="col-sm-12 col-md-2">
                        <div class="d-grid">
                          <button class="btn btn-success btn-sm" type="button" (click)="newEntity('owner')">new
                            +</button>
                        </div>
                      </div>
                    </div>


                    <div class="mb-2 col-12 row" *ngIf="actions.showPayeeForm">
                      <label for="payee-dropdown"
                        class="col-sm-12 col-md-2 col-form-label col-form-label-sm">Payee</label>
                      <div class="col-sm-12 col-md-8">
                        <select id="payee-dropdown" class="form-select form-control-sm" [(ngModel)]="selects.payee"
                          [ngModelOptions]="{standalone: true}">
                          <option selected disabled value="">Select a Payee</option>
                          <option *ngFor="let payee of suggestions.payee" [value]="payee?.uid">{{ payee?.firstname }} {{ payee?.lastname }} ({{ payee?.USEF }})</option>
                        </select>
                      </div>
                      <button class="col-sm-12 col-md-2 btn btn-success btn-sm" type="button"
                        (click)="newEntity('payee')">new +</button>
                    </div>

                    <div class="mb-2 col-12">
                      <div class="form-check form-checkbox-dark col-sm-12 col-md-8">
                        <input type="checkbox" class="form-check-input" id="customCheckcolor7"
                          (change)="differentPayee($event)">
                        <label class="form-check-label" for="customCheckcolor7">Check if payee different than
                          Owner</label>
                      </div>
                    </div>

                    <div class="mb-2 col-12 row">
                      <label for="rider-dropdown" class="col-sm-12 col-md-2 col-form-label col-form-label-sm">TAX
                        ID</label>
                      <div class="col-md-10 col-sm-12">
                        <div class="col-sm-12 col-md-5">
                          <input type="text" class="form-control form-control-sm" id="tax-id" [(ngModel)]="taxId"
                            [ngModelOptions]="{standalone: true}">
                        </div>
                      </div>
                    </div>

                    <!-- Divisions and classes for non-reining shows -->
                    <div class="mt-3 mb-2 col-12 row text-center" (change)="getDivisionsByType()">
                      <div class="col-md-3 col-sm-3">
                        <label class="label-radio-button" for="customRadio3" [class.selected]="discipline === 'hunters'">Hunters
                          <input type="radio" id="customRadio3" name="customRadio1" [(ngModel)]="discipline" value="hunters" required>
                        </label>
                      </div>
                      <div class="col-md-3 col-sm-3">
                        <label class="label-radio-button" for="customRadio4" [class.selected]="discipline === 'jumpers'">Nat jumpers
                          <input type="radio" id="customRadio4" name="customRadio1" [(ngModel)]="discipline" value="jumpers" required>
                        </label>
                      </div>
                      <div class="col-md-3 col-sm-3">
                        <label class="label-radio-button" for="customRadio5" [class.selected]="discipline === 'FEI_jumpers'">FEI Jumpers
                          <input type="radio" id="customRadio5" name="customRadio1" [(ngModel)]="discipline" value="FEI_jumpers" required>
                        </label>
                      </div>
                      <div class="col-md-3 col-sm-3">
                        <label class="label-radio-button" for="customRadio6" [class.selected]="discipline === 'Non_showing'">Non showing
                          <input type="radio" id="customRadio6" name="customRadio1" [(ngModel)]="discipline" value="Non_showing" required>
                        </label>
                      </div>
                    </div>

                    <div class="accordion" id="accordionExample" *ngIf="divisions && divisions.length > 0">
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingOne">
                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            Add classes (optional)
                          </button>
                        </h2>
                        <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne"
                          data-bs-parent="#accordionExample">
                          <div class="accordion-body row">
                            <ul class="list-group col-md-3 col-sm-12" *ngFor="let division of divisions">
                              <div class="bg-secondary text-white p-2 mb-2">{{ division.name }}</div>
                              <li *ngFor="let class of division.pruebas"
                                style="position: relative;display: block;color: black;font-size: small;">
                                <input class="form-check-input me-1" type="checkbox" [value]="class.ipc"
                                  aria-label="..." (change)="addClassToEntry(class.ipc)">
                                <strong>{{ class.numero }}</strong> {{ class.nombre }}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div> <!-- end card-->

            <div class="col-md-6 col-sm-12">
              <div class="card card-body">
                <div class="col-12 row">
                  <div class="col-sm-12 col-md-12">
                    <h5>EMERGENCY CONTACT <span class="text-danger">*</span></h5>
                    <form class="row row-cols-lg-12 g-3 align-items-center needs-validation" [formGroup]="emergencyForm" autocomplete="off">
                      <div class="col-sm-12 col-md-6">
                        <input type="text" class="form-control form-control-sm" id="floatingEmergencyName"
                          placeholder="Name" formControlName="fullname" required />
                      </div>
                      <div class="col-sm-12 col-md-6">
                        <input type="text" class="form-control form-control-sm" id="floatingEmergencyPhone"
                          placeholder="Phone number" formControlName="phone" required />
                      </div>
                    </form>
                  </div>

                  <div class="col-sm-12 col-md-12">
                    <h5>STABLING INFO</h5>
                    <form class="row row-cols-lg-12 g-3 align-items-center needs-validation" [formGroup]="stablingForm" autocomplete="off">
                      <div class="col-sm-12 col-md-12">
                        <input type="text" class="form-control form-control-sm" id="floatingStabling" placeholder="Stable with" formControlName="with" />
                      </div>
                    </form>
                  </div>
                  <div class="col-sm-12 col-md-12" >
                    <div>
                      <!-- Cargos -->
                      <h4 *ngIf="conceptos.length > 0">Additionals</h4>
                      <div class="row">
                        <div class="col-12">
                          <div class="row">

                            <div class="col-12" *ngFor="let concepto of conceptos">
                              <form class="form-inline mx-auto row" style="width: fit-content;">
                                <div class="form-group col-6">
                                  <input type="text" readonly="" class="form-control-plaintext"
                                    [value]="concepto.nombre" disabled>
                                </div>
                                <div class="form-group col-3 concepto-input">
                                  <input (change)="agregarCargo(concepto.id, concepto.monto, concepto.nombre, $event)"
                                    type="number" min="0" max="100"
                                    style="width: 2.5rem; padding: 0; text-align: center;" class="form-control"
                                    placeholder="Qty">
                                </div>
                                <div class="form-group col-3">
                                  <label style="color: #212529; font-weight: normal;">{{ concepto.monto | currency
                                    }}</label>
                                </div>
                              </form>
                            </div>

                            <div class="col-12 text-center">
                              <h5 class="mb-0">TOTAL : {{ totalCargos() | currency
                                }} (to be paid at show)</h5>
                            </div>
                            <div class="col-12">
                              <h4 class="text-center">{{ entryAmountWithCCFee | currency }} Deposit Due {{ entriesDue | date }}</h4>
                              <p class="mb-0 text-center" style="font-size: x-small;">ONE HORSE PER ENTRY FORM

                                <br>All horses must have membership cards, a valid coggins and health certificate.
                                <br>This amount includes a {{comision_stripe * 100}}% retail adjustment for Credit Card
                                payments.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div class="col-md-6 col-sm-12">
                  <h5 class="card-title mt-2">Trainer information</h5>
                  <form class="row row-cols-lg-12 g-3 align-items-center needs-validation" [formGroup]="trainerForm" autocomplete="off">
                    <div class="col-sm-12 col-md-4">
                      <label for="floatingUSEFTrainer">USEF#</label>
                      <input (change)="getTrainerByUSEF()" type="text" class="form-control" id="floatingUSEFTrainer" placeholder="USEF #" formControlName="USEF"/>
                    </div>

                    <div class="col-sm-12 col-md-4 autocomplete">
                      <label for="floatingTrainerfirsname">Firstname <span class="text-danger">*</span></label>
                      <input (keyup)="search('trainer',$event)" type="text" class="form-control" id="floatingTrainerfirsname" placeholder="Firstname" formControlName="firstname" required />
                      <div class="autocomplete-items" >
                        <div *ngFor="let t of searchTerms.trainer" (click)="autocomplete('trainer',t)">
                          <a>{{ t.firstname }} {{ t.lastname }}</a><input type="hidden">
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-12 col-md-4">
                      <label for="floatingTrainerlastname">Lastname <span class="text-danger">*</span></label>
                      <input type="text" class="form-control" id="floatingTrainerlastname" placeholder="Lastname" formControlName="lastname" required />
                    </div>

                  </form>
                </div>
                <div class="col-md-6 col-sm-12">
                  <h5 class="card-title mt-2">Horse information</h5>
                  <form class="row row-cols-lg-12 g-3 align-items-center needs-validation" [formGroup]="horseForm" autocomplete="off">
                    <div class="col-sm-12 col-md-3">
                      <label for="floatingUSEF">USEF#</label>
                      <input (change)="getHorseByUSEF()" type="text" class="form-control" id="floatingUSEF" placeholder="USEF #" formControlName="USEF"/>
                    </div>

                    <div class="col-sm-12 col-md-3">
                      <label for="floatingFEI">FEI #</label>
                      <input (change)="getHorseByFEI()" type="text" class="form-control" id="floatingFEI" placeholder="FEI Number" formControlName="FEI"/>
                    </div>

                    <div class="col-sm-12 col-md-6 autocomplete">
                      <label for="floatingHorse">Name<span class="text-danger">*</span></label>
                      <input (keyup)="search('horse',$event)" type="text" class="form-control" id="floatingHorse" placeholder="Horse name" formControlName="name" required />
                      <div class="autocomplete-items" >
                        <div *ngFor="let t of searchTerms.horse" (click)="autocomplete('horse',t)">
                          <a>{{ t.name }}</a><input type="hidden">
                        </div>
                      </div>
                    </div>

                  </form>
                </div>
                <div class="col-md-6 col-sm-12">
                  <h5 class="card-title mt-2">Owner information</h5>
                  <form class="row row-cols-lg-12 g-3 align-items-center needs-validation" [formGroup]="ownerForm" autocomplete="off">
                    <div class="col-sm-12 col-md-3">
                      <label for="floatingUSEFOwner">USEF#</label>
                      <input (change)="getOwnerByUSEF()" type="text" class="form-control" id="floatingUSEFOwner" placeholder="USEF #" formControlName="USEF"/>
                    </div>

                    <div class="col-sm-12 col-md-3">
                      <label for="floatingFEIOwner">FEI #</label>
                      <input type="text" class="form-control" id="floatingFEIOwner" placeholder="FEI Number" formControlName="FEI"/>
                    </div>

                    <div class="col-sm-12 col-md-3 autocomplete">
                      <label for="floatingOwnerfirsname">Firstname<span class="text-danger">*</span></label>
                      <input (keyup)="search('owner',$event)" type="text" class="form-control" id="floatingOwnerfirsname" placeholder="Firstname" formControlName="firstname" required />
                      <div class="autocomplete-items" >
                        <div *ngFor="let t of searchTerms.owner" (click)="autocomplete('owner',t)">
                          <a>{{ t.firstname }} {{ t.lastname }}</a><input type="hidden">
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-12 col-md-3">
                      <label for="floatingOwnerlastname">Lastname<span class="text-danger">*</span></label>
                      <input type="text" class="form-control" id="floatingOwnerlastname" placeholder="Lastname" formControlName="lastname" required />
                    </div>

                  </form>
                </div>
                <div class="col-md-6 col-sm-12">
                  <h5 class="card-title mt-2">Rider information</h5>
                  <form class="row row-cols-lg-12 g-3 align-items-center needs-validation" [formGroup]="riderForm" autocomplete="off">
                    <div class="col-sm-12 col-md-3">
                      <label for="floatingUSEFRider">USEF#</label>
                      <input (change)="getRiderByUSEF()" type="text" class="form-control" id="floatingUSEFRider" placeholder="USEF #" formControlName="USEF" />
                    </div>

                    <div class="col-sm-12 col-md-3">
                      <label for="floatingFEIRider">FEI #</label>
                      <input type="text" class="form-control" id="floatingFEIRider" placeholder="FEI Number" formControlName="FEI"/>
                    </div>

                    <div class="col-sm-12 col-md-3 autocomplete">
                      <label for="floatingRiderfirsname">Firstname<span class="text-danger">*</span></label>
                      <input (keyup)="search('rider',$event)" type="text" class="form-control" id="floatingRiderfirsname" placeholder="Firstname" formControlName="firstname" required />
                      <div class="autocomplete-items" >
                        <div *ngFor="let t of searchTerms.rider" (click)="autocomplete('rider',t)">
                          <a>{{ t.firstname }} {{ t.lastname }}</a><input type="hidden">
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-12 col-md-3">
                      <label for="floatingRiderlastname">Lastname<span class="text-danger">*</span></label>
                      <input type="text" class="form-control" id="floatingRiderlastname" placeholder="Lastname" formControlName="lastname" required />
                    </div>

                  </form>
                </div> -->
              </div>
            </div> <!-- end card-->

            <div class="card">
              <div class="card-body">
                <!-- Formualario responsable -->
                <h4 class="header-title mb-3">Payment Information</h4>
                <div class="row justify-content-center">
                  <ng-container *ngFor="let rider of this.selects.riders">
                    <div class="col-sm-12 col-md-4">
                      <div class="form-group mb-0 wrapperRider">
                        <div>
                          <signature-pad [id]="rider?.uid" class="rider" [options]="signaturePadOptions" (onBeginEvent)="drawStart('rider')" (onEndEvent)="drawComplete('rider')"></signature-pad>
                        </div>
                      </div>
                      <div class="form-group mb-2">
                        <div class="signature-pad--footer float-left">
                          <div class="description">Sign above (Rider) Required<span class="text-danger">*</span></div>
                        </div>
                        <div class="float-right">
                          <button class="btn btn-light mr-3 float-right" type="button" (click)="clearDraw('rider', rider?.uid)">Clear</button>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
                <div class="row justify-content-center">
                  <div class="col-sm-12 col-md-4">
                    <div class="form-group mb-0" id="wrapperTrainer">
                      <div>
                        <signature-pad class="trainer" [options]="signaturePadOptions" (onBeginEvent)="drawStart('trainer')" (onEndEvent)="drawComplete('trainer')"></signature-pad>
                      </div>
                    </div>
                    <div class="form-group mb-2">
                      <div class="signature-pad--footer float-left">
                        <div class="description">Sign above (Trainer) Required<span class="text-danger">*</span></div>
                      </div>
                      <div class=" float-right">
                        <button class="btn btn-light mr-3 float-right" type="button" (click)="clearDraw('trainer')">Clear</button>
                        <!-- [disabled]="form.disabled" -->
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-4">
                    <div class="form-group mb-0" id="wrapperOwner">
                      <div>
                        <signature-pad class="owner" [options]="signaturePadOptions" (onBeginEvent)="drawStart('owner')" (onEndEvent)="drawComplete('owner')"></signature-pad>
                      </div>
                    </div>
                    <div class="form-group mb-2 save-top-area">
                      <div class="signature-pad--footer float-left">
                        <div class="description">Sign above (Owner) Required<span class="text-danger">*</span></div>
                      </div>
                      <div class=" float-right">
                        <button class="btn btn-light mr-3" type="button" (click)="clearDraw('owner')">Clear</button>
                        <!-- [disabled]="form.disabled" -->
                      </div>
                    </div>
                  </div>
                </div>



                <form class="needs-validation text-center">
                  <div class="form-group mb-3">
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" class="custom-control-input" name="termsAndConditions"
                        id="termsAndConditions" [(ngModel)]="termsAndConditions"
                        (ngModelChange)="TermsAndConditionsAggrement()" required>
                      <label class="custom-control-label" for="termsAndConditions"> I accept the <a class="text-dark"
                          (click)="showModal()">Terms and Conditions</a><span class="text-danger">*</span>
                      </label>
                    </div>
                  </div>
                </form>

                <form class="needs-validation">
                  <div class="col-12 text-center">
                    <button type="button" class="btn btn-outline-primary mr-1 mb-3" (click)="save()"> Save
                      Entry</button>
                  </div>
                </form>
                <!--p class="text-center">ALL CHECKS IN U.S. FUNDS. PLEASE MAKE CHECKS OUT TO SPLIT ROCK JUMPING TOUR.</p>
                  <p class="text-center">Please mail checks to 5425 Greenwich Pike Lexington, KY, 40511. Attn. Kaitlin Farmer</p-->
                <p class="text-center">In the event of the show being cancelled, all entries will be fully refunded.</p>
              </div>
            </div>

          </div> <!-- end col-->
        </div>
      </div>
    </div>
  </div>
</div>
