import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { registerLocaleData } from '@angular/common';
import { environment } from './environments/environment';
import * as Sentry from "@sentry/angular";
import localeEs from '@angular/common/locales/es';
import localeEn from '@angular/common/locales/en';
import LogRocket from 'logrocket';

import packageJson from './../package.json'; // Adjust the path as necessary
const version = packageJson.version;

LogRocket.init('shjn82/ed-online-entries');

Sentry.init({
  dsn: "https://fb1fab42cb4f3f293183a8f266d2e775@o4507624615116800.ingest.us.sentry.io/4507731716014080",
  release: version,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      networkDetailAllowUrls: [
        'https://myapi.ushja.org',
        'https://api.usef.org/api',
        'https://api.equestrian.digital',
        'https://nest.equestrian.digital/v1'

      ],
    }),
    Sentry.httpClientIntegration()
  ],
  sendDefaultPii: true,
  beforeSend(event) {
    return event;
  },
  //Environment
  environment: environment.production ? "production" : "development",
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/api\.equestrian\.digital/, /^https:\/\/nest\.equestrian\.digital\/v1/],
  // Session Replayapi.equestrian.digital/
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


// Register locale data
registerLocaleData(localeEs);
registerLocaleData(localeEn);

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
